import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/SEO/seo'
import WorkBlock from '../components/Blocks/workBlock'

export default () => {
  return (
    <Layout dark={true}>
      <SEO
        pageTitle={'404 ERROR!'}
        pageDescription={'404 error... page cannot be found'}
        pageImage={false}
        url={false}
      />
      <div className="container-fluid">
        <div className="row">
          <div className="mt-5 pt-5 col-lg-4 offset-lg-1">
            <h1 className="display-1">404 ERROR!</h1>
            <p>
              Unfortunately the page you were looking for cannot be found at
              this time. Please bear with us whilst we finish adding content to
              the site.
            </p>
            <p>
              In the mean time please navigate the site via the menu or check
              out the work below.
            </p>
          </div>
        </div>
        <React.Fragment>
          <WorkBlock
            work={'/work/portfolio/'}
            image_leftright={'RIGHT'}
          />
        </React.Fragment>
      </div>
    </Layout>
  )
}
